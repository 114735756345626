@import 'styles/utils/variables';

.selectInput {
  & div[class$='ant-select-selector'] {
    border-radius: 3px !important;
    background: $background-color-input !important;
    border: 0.5px solid $border-color-input !important;
    font-weight: 600;

    & span[class$='ant-select-selection-placeholder'] {
      color: $text-color-placeholder !important;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }

  &[class*='ant-select-open'] > div[class$='ant-select-selector'],
  &[class*='ant-select-focused'] > div[class$='ant-select-selector'],
  &:hover div[class$='ant-select-selector'],
  &:focus div[class$='ant-select-selector'] {
    border-color: $border-color-primary-hover !important;
  }

  span[class='ant-select-selection-item'] {
    border: none;
  }

  &[class*='ant-select-lg'] div[class='ant-select-selection-search'],
  &[class*='ant-select-lg'] input[class='ant-select-selection-search-input'],
  &[class*='ant-select-lg'] input[class='ant-select-selection-search-mirror'] {
    height: 3.254rem;
    line-height: 3.254rem;
  }
}

.default {
  & div[class$='ant-select-selector'] {
    background: $background-color-input !important;
  }
}

.secondary {
  & div[class$='ant-select-selector'] {
    background: $background-color-secondary !important;
  }
}

.error {
  & div[class$='ant-select-selector'] {
    color: $text-color-error;
  }
}

.success {
  & div[class$='ant-select-selector'] {
    color: $text-color-success;
  }
}

.warning {
  & div[class$='ant-select-selector'] {
    color: $text-color-warning;
  }
}
