@import 'styles/utils/variables';

.authHeader {
  border-bottom: 1px solid $border-color-secondary-light;
  display: flex;
}

.logoWrap {
  margin-bottom: 0 !important;
}
