@import 'styles/utils/variables';

.pageHeader {
  height: auto !important;
  line-height: 1 !important;
  padding: 2.4rem 0 0.8rem 0 !important;
}

.menuContainer {
  margin-bottom: 2.4rem;
}

.title {
  white-space: break-spaces !important;
}
