@import 'styles/utils/mixins';
@import 'styles/utils/variables';

.navMenu {
  min-width: 300px !important;
  justify-content: center;
  border: transparent !important;
}

.navMenuItem {
  font-size: 1.4rem;
  padding: 0 0.9rem !important;

  @include mq(lg) {
    font-size: 1.6rem;
  }

  &::after {
    right: 0.9rem !important;
    left: 0.9rem !important;
    border-bottom: none !important;
  }
}

.activeLink {
  color: $text-color-selected !important;
  padding: 2rem;
  border-bottom: 2px solid $text-color-selected !important;
}
