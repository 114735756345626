@import 'styles/utils/variables';

.formItem {
  width: 100%;
}

.formItemLabel {
  & div[class$='ant-form-item-label'] {
    line-height: 1.3;

    & label {
      color: $text-color-placeholder;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}
