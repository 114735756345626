@import '../utils/variables';
@import 'styles/utils/mixins';

html {
  font-size: 62.5%; // 1rem === 10px
  font-family: $font-family;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: #fff;
}

#root {
  display: flex;
  flex-direction: column;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}

img {
  width: 100%;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

//** OVERRIDES the user agent style sheets inside inputs / textarea / selects
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-input {
  color: $text-color-primary;
}

.ant-layout,
.ant-layout-header,
.ant-layout-footer {
  background-color: transparent !important;
}

.ant-input-prefix {
  margin-right: 1.6rem !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-input-group-addon,
.ant-form-item-has-error .ant-input-group-addon,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: $background-color-error !important;
}

//** OVERRIDES countDown styles
.ant-statistic-content {
  font-size: 1.2rem !important;
}

//** PAGE CONTAINER
.pageLayout {
  height: calc(100vh - 18rem);
}

//** TYPOGRAPHY
.ant-typography.ant-typography-success {
  color: $text-color-success;
}

.ant-typography.ant-typography-warning {
  color: $text-color-warning;
}

.ant-typography.ant-typography-danger {
  color: $text-color-error;
}

.ant-typography.ant-typography-secondary {
  color: $text-color-secondary;
}

//** Overrides ant style injection (added only with responsive des)
body[class='ant-scrolling-effect'] {
  width: 100% !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  ::after {
    border-bottom: none;
  }
}

//? Page controls action button
.actionBtn {
  display: flex;
  align-items: center;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 3px !important;
  font-size: 1.2rem !important;
  font-weight: 600;

  & svg {
    margin-right: 0.2rem;
  }
}

//** Pages ScrollLayout
.profileScrollLayout {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }
}

//** SUB PAGE CONTAINER
.subPageLayout {
  height: 100%;
  flex: auto;
}

.tdNumberRightAlign {
  text-align: right;
}
