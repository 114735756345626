.modalWindow {
  padding-bottom: 0 !important;

  & span[class$='ant-modal-close-x'] {
    height: 7.1rem;
    width: 6rem;
    line-height: 7.1rem;
  }

  & div[class$='ant-modal-header'] {
    padding: 2.4rem;
  }

  & div[class$='ant-modal-content'] {
    border-radius: 6px;

    & div[class$='ant-modal-header'] {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
  }

  & div[class$='ant-modal-footer'] {
    padding: 2.4rem;
  }
}
