@import 'styles/utils/variables';

input.formEmailInput {
  background-color: $background-color-input;
  border-color: $border-color-input;
  border-radius: 3px;
  font-weight: 600;

  &::placeholder {
    color: $text-color-placeholder;
    font-weight: normal;
    font-size: 1.4rem;
  }
}

span.formInputHasAffix {
  background-color: $background-color-input;
  border-color: $border-color-input;
  border-radius: 3px;
  font-weight: 600;

  & input[type='email'] {
    background: transparent;

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
}
