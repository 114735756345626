.wrapper {
  height: 100%;
}

.contentWrap {
  height: 100%;
}

.content {
  text-align: center;
}

.title {
  font-size: 2.8rem !important;
  line-height: 1.2 !important;
}

.text {
  font-size: 1.8rem !important;
}
