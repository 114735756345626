@import 'styles/utils/variables';

.loaderWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11000;
  background: $background-color-secondary;
}
