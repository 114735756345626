@import 'styles/utils/variables';
@import 'styles/utils/mixins';

.button {
  display: flex !important;
  align-items: center !important;
}

.settingsBtn {
  @extend .button;
  margin: 0 auto;
  padding: 0.4rem 0.3rem !important;
  background: $background-color-input !important;
  border-color: $background-color-input !important;

  &:hover {
    border-color: $border-color-primary-hover !important;
  }
}

.settingsBtnMobile {
  @extend .settingsBtn;
  margin: 0;
  margin-left: auto !important;
  margin-right: 2rem;
}

.actionBtn {
  @extend .button;
  border: none !important;

  @include mq(md) {
    margin: 0;
    margin-left: auto !important;
  }

  & svg {
    margin-right: 1rem;
  }
}
