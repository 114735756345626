@import 'styles/utils/variables';

.authBtn {
  border-color: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;

  & span {
    color: $text-color-primary !important;
    font-size: 1.2rem;
    font-weight: 500;
  }

  & svg {
    margin-right: 0.8rem;
  }
}
