@import 'styles/utils/variables';

span.formInputHasAffix {
  background-color: $background-color-input;
  border-radius: 3px;
  border-color: $border-color-input;
  font-weight: 600;

  &[class*='ant-input-affix-wrapper-focused'],
  &:hover,
  &:focus {
    border-color: $border-color-primary-hover;
  }

  & input[type='password'],
  & input[type='text'] {
    background-color: transparent;
    font-weight: 600;

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
}
