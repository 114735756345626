@import 'styles/utils/variables';

.datePickerInput {
  width: 100%;
  background: $background-color-input !important;
  border: 0.5px solid $border-color-input !important;
  border-radius: 3px !important;

  & div[class$='ant-picker-input'] input {
    font-weight: 600;

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }

  &[class*='ant-picker-focused'],
  &:hover,
  &:focus {
    border-color: $border-color-primary-hover !important;
  }
}
