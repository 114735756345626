.spinner {
  &[class^='ant-spin'] span[class*='ant-spin-dot'] {
    font-size: 40px;
  }

  &[class^='ant-spin ant-spin-sm'] span[class*='ant-spin-dot'] {
    font-size: 30px;
  }

  &[class^='ant-spin ant-spin-lg'] span[class*='ant-spin-dot'] {
    font-size: 50px;
  }
}
