@import 'styles/utils/variables';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 2.6rem;
  font-weight: 700;
  color: $text-color-primary;
  margin-bottom: 2rem !important;
}

.goBack {
  font-size: 1.8rem;
  font-weight: 400;
  color: $text-color-secondary-active;
}
